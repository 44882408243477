<template>
  <v-card flat>
    <v-card-text :class="textClass">
      <!-- progress -->
      <progress-display v-if="visibleProgress">
        <slot name="progress">
          Please wait, processing your request ...
        </slot>
      </progress-display>

      <!-- prompt -->
      <slot name="prompt" v-else></slot>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "PromptWithProgress",
  components: {
    ProgressDisplay: () =>
      import("@/components/shared/core/progress/ProgressDisplay")
  },
  props: {
    visibleProgress: {
      type: Boolean,
      default: false
    },
    textClass: {
      type: String,
      default: "text-lg-subtitle-1 font-weight-medium"
    }
  }
};
</script>
